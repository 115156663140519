section.about-section {
  min-height: 105.6rem;
  max-height: 137.2rem;
  padding-bottom: clamp(5.5rem, 5.5vw, 11rem);
  background-color: #f8f8f8;
  max-width: 144rem;
}

div.about-wrapper {
  width: clamp(20rem, 90%, 120.2rem);
  margin: 0 auto;
  padding-top: 1.6rem;
  border: 0.1rem solid #f8f8f8;
}

h2.about-head {
  /* font-size: clamp(var(--m-medium-font-title), 3vw, 5rem); */
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  /* text-align: center; */
  color: var(--light-blue);
  background-color: #ffffff;
  max-width: 13.3rem;
  border-radius: 5.2rem;
  padding: 0.8rem 1rem;
  display: grid;
  place-items: center;
  margin: 0 auto;
  margin-top: 1rem;
}

article.about-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 2rem; */
  gap: 2.8rem;
  margin-top: 1.7rem;
}

div.about-img-container {
  /* max-width: 50rem; */
  /* width: 100%; */
  max-height: 30rem;
}

img.about-img {
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  object-fit: cover;
}

ul.about-list {
  /* display: grid;
  gap: 4rem; */
  display: flex;
  flex-direction: column;
}

h3.about-list-item-title {
  margin-bottom: 1rem;
  font-size: clamp(1.8rem, 1.8vw, 2.6rem);
  font-weight: 500;
  line-height: clamp(2.194rem, 2.194vw, 3.169rem);
  color: #000000;
  background-color: #d9e5ff80;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 3rem;
  margin-top: 4rem;
}

p.about-list-item-text {
  font-size: clamp(1.4rem, 1.4vw, 1.6rem);
  font-weight: 400;
  line-height: clamp(1.7rem, 1.7vw, 1.95rem);
  color: #000000cc;
}

/* li.about-list-first-item h3.about-list-item-title:first-of-type {
  font-size: clamp(1.8rem, 1.8vw, 4rem);
  font-weight: 500;
  line-height: clamp(2.399, 2.399vw, 5.332rem);
  background-color: transparent;
  padding: 0;
  margin-top: 0rem;
} */

/* li.about-list-first-item p.about-list-item-text:first-of-type {
  font-size: clamp(1.7rem, 1.7vw, 2.5rem);
  font-weight: 400;
  line-height: clamp(1.7rem, 1.7vw, 3.48rem);
  margin-top: 1.4rem;
} */

@media (min-width: 850px) {
  h2.about-head {
    display: unset;
    /* margin: unset; */
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }

  div.about-img-container {
    max-width: 53.3rem;
    max-height: 90.9rem;
  }

  article.about-container {
    grid-template-areas: "text img";
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    padding: 2rem;
  }

  div.about-img-container {
    grid-area: img;
  }

  ul.about-list {
    grid-area: text;
  }
}
