div.loader-container {
  background-color: var(--primary-white-color);
  text-align: center;
}

div.small-loader-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

div.loader-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}

.loader-container svg {
  width: clamp(4rem, 4vw, 16.4rem);
  height: clamp(7.5rem, 7.5vw, 15.9rem);
  animation: spin 2s linear infinite;
}

.small-loader-spinner svg {
  width: clamp(3rem, 3vw, 5.4rem);
  height: clamp(2.5rem, 2.5vw, 4.9rem);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container p {
  font-size: clamp(1.8rem, 1.8vw, 4rem);
  font-weight: var(--normal-fw);
  line-height: 2.2rem;
  letter-spacing: -0.40799999237060547px;
  color: var(--light-blue);
}

.small-loader-spinner p {
  font-size: clamp(1.5rem, 1.5vw, 2rem);
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: -0.40799999237060547px;
  margin-top: 1.2rem;
  color: var(--light-blue);
}

.loader-container span {
  opacity: 0;
  transition: opacity, var(--fast-transition) ease-in;
  animation: fade-in 3s ease-in-out infinite;
  animation-delay: calc(var(--dot) * 700ms);
}

small.loader-slow-network {
  font-size: clamp(1rem, 1vw, 1.2rem);
  margin-top: 0.8rem;
  max-width: 80%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
