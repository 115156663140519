html {
  font-size: 62.5%;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

:root {
  /* Fonts */
  --ex-small-fw: 400;
  --small-fw: 500;
  --normal-fw: 600;
  --heavy-fw: 700;

  /* mobile-view-fonts*/
  /* --m-heading-font: 1.6rem;
  --m-font-title: 2.4rem;
  --m-nav-font: 1.8rem;
  --m-brand-name: 1.6rem; */

  --m-small-text-size: 1.4rem;
  --m-medium-text-size: 1.6rem;
  --m-primary-font: 1.4rem;
  --m-secondary-font: 1.6rem;
  --m-tertiary-font: 1.8rem;
  --m-small-font-title: 2rem;
  --m-medium-font-title: 2.2rem;
  --m-big-font-title: 2.4rem;

  /* line-height */
  --m-ex-small-line-height: 1.95rem;
  --m-small-line-height: 2.438rem;
  --m-normal-line-height: 2.682rem;
  --m-big-line-height: 2.926rem;

  /*desktop-view-fonts*/
  --d-nav-font: 16px;
  --d-brand-name: 36px;
  --d-intro-font: var(--d-brand-name);
  --d-heading-font: 32px;
  --d-subhead-font: 24px;
  --d-text-font: 16px;
  --d-small-text-font: 14px;

  /* color */
  --primary-white-color: #ffffff;
  --secondary-white: #ffffffb2;
  --tertiary-white: #f5f8f3;

  --primary-dark: #272628;
  --secondary-dark: #000000cc;
  --tertiary-dark: #000000;
  --light-blue: #407bff;
  /* 
  --primary-text-color: ;
  --secondary-text-color: ;
  --text-color: ;
  --bg-color: ;
  --btn-bg-color: ;
  --error-color: ;
  --sucess-color: ;
  --heading-color: ;
  --subheading-color: ;
  --menu-bg-color: ;
  --placeholder-color: ;
  --hover-color: ; */

  /* rgb */
  --dark-rgb: rgb(25, 25, 112, 0.2);
  --light-dark-box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);

  /* transiton */
  --fast-transition: 0.3s;
  --normal-transition: 0.6s;
  --slow-transition: 1s;
}

main {
  display: block;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

img {
  border-style: none;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a,
button {
  cursor: pointer;
  font-family: inherit;
  border: none;
  outline: none;
}

button {
  background-color: transparent;
}

input,
textarea {
  outline: none;
  border: none;
  resize: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

textarea::-webkit-scrollbar {
  display: none;
}

button,
select {
  /* 1 */
  text-transform: none;
}

li {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; 
} */

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

body {
  background-color: var(--primary-white-color);
}

@media (min-width: 768px) {
  div.app-container {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    max-width: 144rem;
  }
}
