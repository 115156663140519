section.support-section {
  position: relative;
  max-width: 144rem;
}

h2.support-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--light-blue);
  letter-spacing: 4%;
  text-align: center;
  margin-top: 3.7rem;
}

article.support-container {
  margin-top: 3.5rem;
}

h3.support-intro {
  font-size: clamp(2rem, 2vw, 4rem);
  font-weight: var(--normal-fw);
  line-height: clamp(var(--m-normal-line-height), 2.6vw, 5.332rem);
  color: #000000;
}

p.support-intro {
  color: #000000cc;
  font-size: clamp(1.5rem, 1.5vw, 2.5rem);
  font-weight: 400;
  line-height: clamp(1.829rem, 1.829vw, 3.48rem);
  margin-top: 1.6rem;
}

article.support-container {
  width: clamp(20rem, 87%, 118rem);
  margin: 0 auto;
}

ul.support-list {
  display: grid;
  gap: 2.5rem;
  margin-top: 2.7rem;
  /* max-width: 76.1rem; */
  /* max-height: 78.8rem; */
}

li.support-list-item {
  min-height: 27.1rem;
  border-radius: 1.5rem;
  color: #fff;
  /* max-width: 36.3rem; */
}

li.support-list-item:not(:nth-child(3)) {
  padding: 0rem 1.3rem;
}

li.bl {
  background-color: var(--light-blue);
}

li.g {
  background-color: #888888;
}

img.support-list-item-img {
  width: 100%;
  height: 32.2rem;
  border-radius: 2rem;
}

img.support-list-img {
  width: clamp(4.684rem, 4.694vw, 5.2rem);
  height: 4.7rem;
  margin-top: 4.2rem;
}

h4.support-list-item-title {
  font-size: clamp(1.8rem, 1.8vw, 2.6rem);
  font-weight: 500;
  line-height: clamp(2.438rem, 2.438vw, 3.169rem);
  margin-top: 4rem;
}

p.support-list-item-text {
  font-size: clamp(1.4rem, 1.4vw, 1.6rem);
  font-weight: 400;
  line-height: clamp(1.707rem, 1.707vw, 1.95rem);
  letter-spacing: 5%;
  margin-top: 0.8rem;
  margin-bottom: 3.5rem;
}

@media (min-width: 768px) {
  article.support-container {
    display: grid;
    gap: 5rem;
    /* grid-template-columns: minmax(20, 1fr) minmax(20,1fr); */
    grid-template-columns: 1.3fr 0.7fr;
    margin-top: 2.4rem;
    align-items: center;
  }

  article.support-container > div {
    max-height: 78.8rem;
  }

  ul.support-list {
    gap: 2.9rem;
    grid-template-columns: 1fr 1fr;
  }

  /* li.support-list-item:nth-child(3) {
    grid-column: 1/3;
    width: 100%;
    max-width: 100%;
  } */

  /* li.support-list-item { */
  /* if there is an issue with the grid display */
  /* max-width: 36.3rem; */
  /* } */

  li.support-list-item:not(:nth-child(3)) {
    padding: 0rem 2.3rem;
  }

  li.support-list-item {
    padding: 0rem 2.3rem;
  }

  div.support-img {
    max-width: 42.8rem;
    max-height: 72.6rem;
  }

  img.support-img {
    width: 100%;
    height: 100%;
    height: 50rem;
    border-radius: 3rem;
    object-fit: cover;
  }

  h4.support-list-item-title {
    margin-top: 3.6rem;
  }
}

@media (min-width: 1000px) {
  article.support-container {
    align-items: unset;
  }

  img.support-img {
    /* height: calc(100% - 6rem); */
    height: 100%;
    /* margin-top: 6rem; */
  }
}
