aside.waitlist {
  max-width: 84rem;
  max-height: 60.6rem;
  border-radius: 3rem;
  box-shadow: 0 0.4rem 0.6rem rgb(0, 0, 0, 0.1);
  display: grid;
  align-items: center;
  background-color: #fff;
  border: 0.1rem solid rgb(0, 0, 0, 0.1);
  margin: 0.8rem;
  position: fixed;
  top: 2rem;
  z-index: 2;
  width: clamp(20rem, 96%, 84rem);
}

button.close-waitlist {
  /* justify-self: end; */
  display: flex;
  margin-left: auto;
  margin-top: 2rem;
  transform: translateX(-2rem);
}

img.waitlist {
  width: clamp(7.1rem, 7.1vw, 11.4rem);
  height: clamp(8.8rem, 8.8vw, 14.2rem);
  margin: 0 auto;
  margin-top: clamp(3.7rem, 3.7vw, 3.3rem);
}

form.waitlist {
  margin-top: clamp(3.4rem, 3.4vw, 6rem);
  padding-bottom: clamp(8.6rem, 8.6vw, 9.1rem);
  width: 98%;
  justify-self: center;
  display: grid;
  max-width: 54rem;
}

input.waitlist {
  font-size: clamp(1.6rem, 1.6vw, 2rem);
  font-weight: 400;
  line-height: clamp(1.95rem, 1.95vw, 2.438rem);
  border: 0.1rem solid #dadada;
  border-radius: 0.6rem;
  margin-top: clamp(6.8rem, 6.8vw, 6rem);
  height: clamp(4.7rem, 4.7vw, 5.6rem);
  width: 98%;
  max-width: 54rem;
  padding: 1rem;
}

input.waitlist::placeholder {
  color: #696969;
}

p.waitlist {
  font-size: clamp(1.6rem, 1.6vw, 2rem);
  font-weight: 400;
  line-height: clamp(1.95rem, 1.95vw, 2.438rem);
  color: #000;
  text-align: center;
  max-width: 45.9rem;
  margin: 0 auto;
}

button.waitlist {
  margin-top: clamp(1.2rem, 1.2vw, 1.6rem);
  background-color: var(--light-blue);
  width: 98%;
  justify-self: center;
  border-radius: 1.2rem;
  height: clamp(5.2rem, 5.2vw, 5.4rem);
  font-size: clamp(1.8rem, 1.8vw, 2rem);
  font-weight: 500;
  line-height: clamp(2.194rem, 2.194vw, 2.438rem);
}

button.waitlist div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

button.waitlist div svg {
  margin-left: 0.3rem;
  transform: translateY(0.1rem);
}
