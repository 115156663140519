section.community {
  position: relative;
  max-width: 144rem;
  /* min-height: 45.2rem; */
  height: clamp(45.2rem, 45.2vw, 88rem);
  color: #ffffff;
  /* border: 0.1rem solid black; */
}

div.community-container {
  display: grid;
  padding-left: 3rem;
}

img.community {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
}

.overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5); /* Color to blend with */
  mix-blend-mode: multiply; /* This blend mode darkens the image */
  opacity: 0.5; /* Adjust opacity to control the darkness level */
}

h2.community {
  font-family: Playfair Display;
  margin-top: clamp(7.9rem, 7.9vw, 24.8rem);
  font-size: clamp(3rem, 3vw, 7rem);
  font-weight: 800;
  line-height: clamp(3.999rem, 3.999vw, 9.331rem);
  /* margin-top: 7.9rem; */
}

p.community {
  font-family: Playfair Display;
  margin-top: clamp(1rem, 1vw, 3rem);
  font-size: clamp(1.4rem, 1.4vw, 3rem);
  font-weight: 500;
  line-height: clamp(1.707rem, 1.707vw, 3.999rem);
  width: clamp(30rem, 30vw, 71.4rem);
}

a.community {
  font-family: Montserrat;
  border: 0.1rem solid #ffffff;
  font-size: clamp(1.5rem, 1.5vw, 2.2rem);
  font-weight: 500;
  line-height: clamp(2rem, 2vw, 2.682rem);
  border-radius: 1rem;
  padding: 1.8rem;
  margin-top: clamp(3.1rem, 3.1vw, 6.8rem);
  color: #ffffff;
  /* max-width: 17.8rem; */
  width: fit-content;
}

@media (min-width: 768px) {
  section.community {
    max-height: 88rem;
  }

  div.community-container {
    padding-left: 10.9rem;
  }
}
