section.contact-section {
  background-color: #f8f8f8;
  /* display: flex; */
  max-width: 144rem;
  border: 0.1rem solid #f8f8f8;
  margin-top: clamp(3.7rem, 7vw, 13.5rem);
}

div.contact-wrapper {
  display: grid;
  justify-content: center;
  width: 90%;
  /* min-height: 67.8rem; */
  /* max-height: 115rem; */
  margin: 0 auto;
  margin-top: clamp(3.5rem, 3.5vw, 14.2rem);
  margin-bottom: clamp(5.6rem, 10vw, 23rem);
}

div.contact-intro {
  max-width: 53.8rem;
  max-height: 16.5rem;
  /* display: grid; */
}

h2.contact-head {
  font-size: clamp(2rem, 2vw, 4rem);
  font-weight: 600;
  line-height: clamp(2.438rem, 2.438vwvw, 4.876rem);
  color: var(--light-blue);
}

p.contact-text {
  font-size: clamp(1.6rem, 1.6vw, 2.5rem);
  font-weight: 400;
  line-height: clamp(1.95rem, 1.95vw, 3.48rem);
  margin-top: clamp(1rem, 1vw, 2.6rem);
}

form.contact-form {
  max-width: 54rem;
  display: flex;
  flex-direction: column;
  margin-top: 4.3rem;
}

input.landing-page-contact-input,
textarea.landing-page-contact-input {
  border-radius: 0.6rem;
  border: 0.1rem solid #dadada;
  padding: 1rem;
  font-size: clamp(1.6rem, 1.6vw, 2rem);
  font-weight: 400;
  line-height: clamp(1.95rem, 1.95vw, 2.438rem);
}

input.landing-page-contact-input {
  height: clamp(4.7rem, 5vw, 5.6rem);
}

input.landing-page-contact-input::placeholder,
textarea.landing-page-contact-input::placeholder {
  color: #696969;
}

input.landing-page-contact-input:nth-child(2),
textarea.landing-page-contact-input {
  margin-top: clamp(1rem, 1vw, 1.6rem);
}

textarea.landing-page-contact-input {
  height: clamp(12.2rem, 10vw, 19.5rem);
}

button.landing-page-contact-btn {
  background-color: #4154f1;
  color: var(--primary-white-color);
  margin-top: clamp(1.2rem, 1.2vw, 1.6rem);
  height: clamp(5.2rem, 5vw, 5.4rem);
  font-size: clamp(1.8rem, 1.8vw, 2rem);
  font-weight: 500;
  line-height: clamp(2.194rem, 2.194vw, 2.438rem);
  border-radius: 1.2rem;
  padding: 1.5rem 3.8rem;
  box-shadow: 0 0.2rem 1rem 0 #00000026;
}

button.landing-page-contact-btn svg {
  margin-left: 0.6rem;
  transition: transform var(--fast-transition) linear;
}
button.landing-page-contact-btn:hover svg {
  transform: translateX(0.2rem);
}

@media (min-width: 768px) {
  div.contact-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 10rem;
    margin-top: clamp(3.5rem, 3.5vw, 14.2rem);
  }

  form.contact-form {
    margin-top: unset;
  }

  input.landing-page-contact-input,
  textarea.landing-page-contact-input {
    border-radius: 1.2rem;
  }
}
