section.hero-section {
  width: clamp(28rem, 100%, 144rem);
  position: relative;
}

aside.plant-icon {
  display: none;
}

article.hero-article {
  width: clamp(20rem, 90%, 120.2rem);
  margin: 0 auto;
  margin-top: clamp(3.2rem, 3.2vw, 9.2rem);
}

header.hero-content {
  display: flex;
  flex-direction: column;
  /* gap: 1.6rem; */
}

section.hero-image-container {
  margin-top: 0.6rem;
}

img.hero-img {
  display: flex;
  width: clamp(28rem, 44vw, 69.5rem);
  height: clamp(28rem, 44vw, 67.7rem);
  min-width: 28rem;
  min-height: 28rem;
  margin: 0 auto;
}

div.launching-soon {
  border: 0.1rem solid #dadadae5;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1rem;
  transition: transform var(--fast-transitioon) linear;
}

div.launching-soon:hover img {
  transform: translateY(-0.2rem);
  animation: rocket 1s linear;
}

@keyframes rocket {
  0% {
    transform: translateY(-0.1rem);
    transform: translateX(-0.1rem);
  }
  30% {
    transform: translateY(-0.2rem);
    transform: translateX(0.1rem);
  }
  60% {
    transform: translateY(-0.1rem);
  }
  80% {
    transform: translateY(-0.2rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

img.launching-soon {
  width: clamp(1.9rem, 1.9vw, 2.533rem);
  height: clamp(1.9rem, 1.9vw, 2.534rem);
  margin-left: 1rem;
}

p.launching-soon {
  font-size: clamp(1.4rem, 1.4vw, 2.2rem);
  font-weight: 500;
  line-height: clamp(1.707rem, 1.707vw, 2.682rem);
  margin-left: 0.6rem;
  margin-right: 1rem;
}

h1.hero-head {
  font-size: clamp(var(--m-big-font-title), 3.5vw, 5rem);
  font-weight: var(--normal-fw);
  line-height: clamp(var(--m-big-line-height), 4vw, 6.1rem);
  margin-top: 1.6rem;
}

h1.hero-head span {
  color: var(--light-blue);
}

p.hero-text {
  font-weight: min(var(--ex-small-fw), var(--small-fw));
  font-size: clamp(1.5rem, 1.5vw, 2.5rem);
  line-height: clamp(1.829rem, 1.829vw, 3rem);
  width: clamp(30rem, 30vw, 50.7rem);
  margin-top: 1.6rem;
}

/* remove later */
div.hero-link-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.1rem;
  width: clamp(30rem, 30vw, 52.8rem);
  margin-top: 1.6rem;
}

a.waitlist {
  font-size: clamp(1.4rem, 1.4vw, 2.2rem);
  font-weight: clamp(500, 500, 600);
  line-height: clamp(1.95rem, 1.95vw, 2.682rem);
  border-radius: 1rem;
  height: clamp(4rem, 4vw, 4.8rem);
  display: grid;
  place-items: center;
}

a.waitlist:first-of-type {
  color: var(--primary-white-color);
  background-color: var(--light-blue);
}

a.waitlist:last-of-type {
  color: var(--light-blue);
  background-color: #fff;
  border: 0.1rem solid var(--light-blue);
}

a.join-us {
  display: grid;
  place-items: center;
  width: clamp(8rem, 8vw, 15.9rem);
  height: clamp(4rem, 4vw, 4.8rem);
  border-radius: 1rem;
  color: var(--primary-white-color);
  background-color: var(--light-blue);
  font-size: clamp(1.4rem, 1.4vw, 2.2rem);
  font-weight: clmap(500, 500, 600);
  line-height: clamp(1.95rem, 1.95vw, 2.682rem);
}

section.hero-fade-in-container {
  padding-bottom: clamp(4.4rem, 4.4vw, 10rem);
}

p.hero-fade-in-text {
  font-size: clamp(1rem, 2vw, 2.2rem);
  font-weight: 500;
  font-style: italic;
  line-height: clamp(1.7rem, 1.7vw, 2.682rem);
  margin-top: clamp(0.5rem, 0.5vw, 1rem);
  color: #000;
  text-align: center;
}

.hero-fade-in-text span {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: calc(0.1s * var(--index));
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (min-width: 580px) {
  article.hero-article {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  article.hero-article {
    gap: 8.2rem;
  }

  section.hero-image-container {
    transform: translateX(1rem);
  }

  img.hero-img {
    width: clamp(28rem, 40vw, 69.5rem);
    height: clamp(28rem, 40vw, 67.7rem);
  }

  div.hero-link-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.2rem;
  }
}

@media (min-width: 835px) {
  section.hero-image-container {
    transform: translateX(6rem);
  }
}
