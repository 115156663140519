section.faq-section {
  width: clamp(28rem, 100%, 144rem);
  min-height: 65rem;
  margin-bottom: 1.3rem;
  position: relative;
  margin-top: clamp(4.4rem, 4.4vw, 10rem);
  position: relative;
}

/* div.faq-container {
  display: grid;
  place-items: center;
} */

div.faq-title-container {
  display: grid;
  place-items: center;
  gap: 1rem;
}

h2.faq-title {
  color: var(--light-blue);
  font-size: clamp(2rem, 2vw, 5rem);
  font-weight: 600;
  line-height: clamp(2.438, 2.438vw, 6.95rem);
  margin-top: 3.6rem;
}

small.faq-text {
  color: var(--tertiary-dark);
  font-weight: var(--ex-small-fw);
  font-size: clamp(1.6rem, 1.6vw, 3rem);
  line-height: clamp(1.95rem, 1.95vw, 3.657rem);
}

ul.faq-list {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr)); */
  gap: 2rem;
  width: clamp(20rem, 87%, 104.9rem);
  margin: 0 auto;
  margin-top: 2.7rem;
}

li.faq-list-item {
  background-color: var(--light-blue);
  width: 100%;
  min-height: clamp(7.8rem, 7.8vw, 10.4rem);
  border-radius: 1rem;
  margin-top: 1rem;
  position: relative;
  transition: height, 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

div.faq-question-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  gap: 2.9rem;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  font-size: clamp(2rem, 2vw, 2.5rem);
}

h3.faq-question {
  color: var(--primary-white-color);
  font-size: clamp(1.6rem, 1.6vw, 2.5rem);
  font-weight: 500;
  line-height: clamp(1.95rem, 1.95vw, 3.48rem);
}

p.faq-answer {
  font-size: clamp(1.4rem, 1.4vw, 2.3rem);
  padding: 1rem;
  line-height: clamp(2rem, 2.5vw, 3.657rem);
}

button.toggle-faq-answer {
  background-color: transparent;
  font-size: clamp(2rem, 2vw, 4rem);
  font-weight: var(--small-fw);
  color: #fff;
}

button.toggle-faq-answer:hover {
  color: #111;
}

button.show-faq {
  color: #111;
  font-size: clamp(3rem, 3vw, 4rem);
  font-weight: var(--small-fw);
}

button.show-faq:hover {
  color: #fff;
}

@media (min-width: 600px) {
  /* ul.faq-list {
    grid-template-columns: 1fr 1fr;
  } */

  div.faq-question-container {
    width: 79%;
  }

  /* Faq background-icons */
  img.faq-icon:first-of-type {
    right: 0;
    top: 9rem;
  }

  img.faq-icon:last-of-type {
    left: 0;
    right: 0;
    bottom: 1.41rem;
  }
}
